// consumer_car_order_getCitiesForCar 城市列表
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
const consumer_car_order_getCitiesForCar = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/car/order/getCitiesForCar',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_car_order_getCitiesForCar;
