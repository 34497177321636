import Q from 'q';
import _ from 'underscore';
import extend from "extend";


export default {
    props: {

    },
    data() {
        return {
            triggerLeft: true,
            triggerRight: true,

            disabledSwitch: false,
            timeTextHourGetLeft: null,          //左侧记录已选择小时总数据
            timeTextMinuteGetLeft: null,        //左侧记录已选择分钟总数据
            clickHourChoiceLeft: null,          //记录左侧已选择的小时的index--高亮提示
            clickMinuteChoiceLeft: null,        //记录左侧已选择的分钟的index--高亮提示
            timeTextHourLeft: '',               //左侧记录已选择小时label数据
            timeTextMinuteLeft: '',             //左侧记录已选择分钟label数据

            timeTextHourGetRight: null,         //右侧记录已选择小时总数据
            timeTextMinuteGetRight: null,       //右侧记录已选择分钟总数据
            clickHourChoiceRight: null,         //记录右侧已选择的小时的index--高亮提示
            clickMinuteChoiceRight: null,       //记录右侧已选择的分钟的index--高亮提示
            timeTextHourRight: '',              //右侧记录已选择小时label数据
            timeTextMinuteRight: '',            //右侧记录已选择分钟label数据

            childrenTime:[
                {
                    value: '00',
                    label: '00',
                    text: '00',
                },
                {
                    value: '01',
                    label: '01',
                    text: '01',
                },
                {
                    value: '02',
                    label: '02',
                    text: '02',
                },
                {
                    value: '03',
                    label: '03',
                    text: '03',
                },
                {
                    value: '04',
                    label: '04',
                    text: '04',
                },
                {
                    value: '05',
                    label: '05',
                    text: '05',
                },
                {
                    value: '06',
                    label: '06',
                    text: '06',
                },
                {
                    value: '07',
                    label: '07',
                    text: '07',
                },
                {
                    value: '08',
                    label: '08',
                    text: '08',
                },
                {
                    value: '09',
                    label: '09',
                    text: '09',
                },
                {
                    value: '10',
                    label: '10',
                    text: '10',
                },
                {
                    value: '11',
                    label: '11',
                    text: '11',
                },
                {
                    value: '12',
                    label: '12',
                    text: '12',
                },
                {
                    value: '13',
                    label: '13',
                    text: '13',
                },
                {
                    value: '14',
                    label: '14',
                    text: '14',
                },
                {
                    value: '15',
                    label: '15',
                    text: '15',
                },
                {
                    value: '16',
                    label: '16',
                    text: '16',
                },
                {
                    value: '17',
                    label: '17',
                    text: '17',
                },
                {
                    value: '18',
                    label: '18',
                    text: '18',
                },
                {
                    value: '19',
                    label: '19',
                    text: '19',
                },
                {
                    value: '20',
                    label: '20',
                    text: '20',
                },
                {
                    value: '21',
                    label: '21',
                    text: '21',
                },
                {
                    value: '22',
                    label: '22',
                    text: '22',
                },
                {
                    value: '23',
                    label: '23',
                    text: '23',
                },
                {
                    value: '24',
                    label: '24',
                    text: '24',
                },
                {
                    value: '25',
                    label: '25',
                    text: '25',
                },
                {
                    value: '26',
                    label: '26',
                    text: '26',
                },
                {
                    value: '27',
                    label: '27',
                    text: '27',
                },
                {
                    value: '28',
                    label: '28',
                    text: '28',
                },
                {
                    value: '29',
                    label: '29',
                    text: '29',
                },
                {
                    value: '30',
                    label: '30',
                    text: '30',
                },
                {
                    value: '31',
                    label: '31',
                    text: '31',
                },
                {
                    value: '32',
                    label: '32',
                    text: '32',
                },
                {
                    value: '33',
                    label: '33',
                    text: '33',
                },
                {
                    value: '34',
                    label: '34',
                    text: '34',
                },
                {
                    value: '35',
                    label: '35',
                    text: '35',
                },
                {
                    value: '36',
                    label: '36',
                    text: '36',
                },
                {
                    value: '37',
                    label: '37',
                    text: '37',
                },
                {
                    value: '38',
                    label: '38',
                    text: '38',
                },
                {
                    value: '39',
                    label: '39',
                    text: '39',
                },
                {
                    value: '40',
                    label: '40',
                    text: '40',
                },
                {
                    value: '41',
                    label: '41',
                    text: '41',
                },
                {
                    value: '42',
                    label: '42',
                    text: '42',
                },
                {
                    value: '43',
                    label: '43',
                    text: '43',
                },
                {
                    value: '44',
                    label: '44',
                    text: '44',
                },
                {
                    value: '45',
                    label: '45',
                    text: '45',
                },
                {
                    value: '46',
                    label: '46',
                    text: '46',
                },
                {
                    value: '47',
                    label: '47',
                    text: '47',
                },
                {
                    value: '48',
                    label: '48',
                    text: '48',
                },
                {
                    value: '49',
                    label: '49',
                    text: '49',
                },
                {
                    value: '50',
                    label: '50',
                    text: '50',
                },
                {
                    value: '51',
                    label: '51',
                    text: '51',
                },
                {
                    value: '52',
                    label: '52',
                    text: '52',
                },
                {
                    value: '53',
                    label: '53',
                    text: '53',
                },
                {
                    value: '54',
                    label: '54',
                    text: '54',
                },
                {
                    value: '55',
                    label: '55',
                    text: '55',
                },
                {
                    value: '56',
                    label: '56',
                    text: '56',
                },
                {
                    value: '57',
                    label: '57',
                    text: '57',
                },
                {
                    value: '58',
                    label: '58',
                    text: '58',
                },
                {
                    value: '59',
                    label: '59',
                    text: '59',
                },
            ],
            timeAllData:[
                {
                    text: '01',
                    acrossDay: 0,
                    value: '01',
                    label: '01',
                    children:[]
                },
                {
                    text: '02',
                    acrossDay: 0,
                    value: '02',
                    label: '02',
                    children:[]
                },
                {
                    text: '03',
                    acrossDay: 0,
                    value: '03',
                    label: '03',
                    children:[]
                },
                {
                    text: '04',
                    acrossDay: 0,
                    value: '04',
                    label: '04',
                    children:[]
                },
                {
                    text: '05',
                    acrossDay: 0,
                    value: '05',
                    label: '05',
                    children:[]
                },
                {
                    text: '06',
                    acrossDay: 0,
                    value: '06',
                    label: '06',
                    children:[]
                },
                {
                    text: '07',
                    acrossDay: 0,
                    value: '07',
                    label: '07',
                    children:[]
                },
                {
                    text: '08',
                    acrossDay: 0,
                    value: '08',
                    label: '08',
                    children:[]
                },
                {
                    text: '09',
                    acrossDay: 0,
                    value: '09',
                    label: '09',
                    children:[]
                },
                {
                    text: '10',
                    acrossDay: 0,
                    value: '10',
                    label: '10',
                    children:[]
                },
                {
                    text: '11',
                    acrossDay: 0,
                    value: '11',
                    label: '11',
                    children:[]
                },
                {
                    text: '12',
                    acrossDay: 0,
                    value: '12',
                    label: '12',
                    children:[]
                },
                {
                    text: '13',
                    acrossDay: 0,
                    value: '13',
                    label: '13',
                    children:[]
                },
                {
                    text: '14',
                    acrossDay: 0,
                    value: '14',
                    label: '14',
                    children:[]
                },
                {
                    text: '15',
                    acrossDay: 0,
                    value: '15',
                    label: '15',
                    children:[]
                },
                {
                    text: '16',
                    acrossDay: 0,
                    value: '16',
                    label: '16',
                    children:[]
                },
                {
                    text: '17',
                    acrossDay: 0,
                    value: '17',
                    label: '17',
                    children:[]
                },
                {
                    text: '18',
                    acrossDay: 0,
                    value: '18',
                    label: '18',
                    children:[]
                },
                {
                    text: '19',
                    acrossDay: 0,
                    value: '19',
                    label: '19',
                    children:[]
                },
                {
                    text: '20',
                    acrossDay: 0,
                    value: '20',
                    label: '20',
                    children:[]
                },
                {
                    text: '21',
                    acrossDay: 0,
                    value: '21',
                    label: '21',
                    children:[]
                },
                {
                    text: '22',
                    acrossDay: 0,
                    value: '22',
                    label: '22',
                    children:[]
                },
                {
                    text: '23',
                    acrossDay: 0,
                    value: '23',
                    label: '23',
                    children:[]
                },
                // {
                //     text: '01',
                //     acrossDay: 1,
                //     value: '25',
                //     label: '次日01',
                //     children:[]
                // },
                // {
                //     text: '02',
                //     acrossDay: 1,
                //     value: '26',
                //     label: '次日02',
                //     children:[]
                // },
                // {
                //     text: '03',
                //     acrossDay: 1,
                //     value: '27',
                //     label: '次日03',
                //     children:[]
                // },
                // {
                //     text: '04',
                //     acrossDay: 1,
                //     value: '28',
                //     label: '次日04',
                //     children:[]
                // },
                // {
                //     text: '05',
                //     acrossDay: 1,
                //     value: '29',
                //     label: '次日05',
                //     children:[]
                // },
                // {
                //     text: '06',
                //     acrossDay: 1,
                //     value: '30',
                //     label: '次日06',
                //     children:[]
                // },
                // {
                //     text: '07',
                //     acrossDay: 1,
                //     value: '31',
                //     label: '次日07',
                //     children:[]
                // },
                // {
                //     text: '08',
                //     acrossDay: 1,
                //     value: '32',
                //     label: '次日08',
                //     children:[]
                // },
                // {
                //     text: '09',
                //     acrossDay: 1,
                //     value: '33',
                //     label: '次日09',
                //     children:[]
                // },
                // {
                //     text: '10',
                //     acrossDay: 1,
                //     value: '34',
                //     label: '次日10',
                //     children:[]
                // },
                // {
                //     text: '11',
                //     acrossDay: 1,
                //     value: '35',
                //     label: '次日11',
                //     children:[]
                // },
                // {
                //     text: '12',
                //     acrossDay: 1,
                //     value: '36',
                //     label: '次日12',
                //     children:[]
                // },
                // {
                //     text: '13',
                //     acrossDay: 1,
                //     value: '37',
                //     label: '次日13',
                //     children:[]
                // },
                // {
                //     text: '14',
                //     acrossDay: 1,
                //     value: '38',
                //     label: '次日14',
                //     children:[]
                // },
                // {
                //     text: '15',
                //     acrossDay: 1,
                //     value: '39',
                //     label: '次日15',
                //     children:[]
                // },
                // {
                //     text: '16',
                //     acrossDay: 1,
                //     value: '40',
                //     label: '次日16',
                //     children:[]
                // },
                // {
                //     text: '17',
                //     acrossDay: 1,
                //     value: '41',
                //     label: '次日17',
                //     children:[]
                // },
                // {
                //     text: '18',
                //     acrossDay: 1,
                //     value: '42',
                //     label: '次日18',
                //     children:[]
                // },
                // {
                //     text: '19',
                //     acrossDay: 1,
                //     value: '43',
                //     label: '次日19',
                //     children:[]
                // },
                // {
                //     text: '20',
                //     acrossDay: 1,
                //     value: '44',
                //     label: '次日20',
                //     children:[]
                // },
                // {
                //     text: '21',
                //     acrossDay: 1,
                //     value: '45',
                //     label: '次日21',
                //     children:[]
                // },
                // {
                //     text: '22',
                //     acrossDay: 1,
                //     value: '46',
                //     label: '次日22',
                //     children:[]
                // },
                // {
                //     text: '23',
                //     acrossDay: 1,
                //     value: '47',
                //     label: '次日23',
                //     children:[]
                // },
            ],
            timeAllDataLeft:[
                {
                    text: '01',
                    acrossDay: 0,
                    value: '01',
                    label: '01',
                    children:[]
                },
                {
                    text: '02',
                    acrossDay: 0,
                    value: '02',
                    label: '02',
                    children:[]
                },
                {
                    text: '03',
                    acrossDay: 0,
                    value: '03',
                    label: '03',
                    children:[]
                },
                {
                    text: '04',
                    acrossDay: 0,
                    value: '04',
                    label: '04',
                    children:[]
                },
                {
                    text: '05',
                    acrossDay: 0,
                    value: '05',
                    label: '05',
                    children:[]
                },
                {
                    text: '06',
                    acrossDay: 0,
                    value: '06',
                    label: '06',
                    children:[]
                },
                {
                    text: '07',
                    acrossDay: 0,
                    value: '07',
                    label: '07',
                    children:[]
                },
                {
                    text: '08',
                    acrossDay: 0,
                    value: '08',
                    label: '08',
                    children:[]
                },
                {
                    text: '09',
                    acrossDay: 0,
                    value: '09',
                    label: '09',
                    children:[]
                },
                {
                    text: '10',
                    acrossDay: 0,
                    value: '10',
                    label: '10',
                    children:[]
                },
                {
                    text: '11',
                    acrossDay: 0,
                    value: '11',
                    label: '11',
                    children:[]
                },
                {
                    text: '12',
                    acrossDay: 0,
                    value: '12',
                    label: '12',
                    children:[]
                },
                {
                    text: '13',
                    acrossDay: 0,
                    value: '13',
                    label: '13',
                    children:[]
                },
                {
                    text: '14',
                    acrossDay: 0,
                    value: '14',
                    label: '14',
                    children:[]
                },
                {
                    text: '15',
                    acrossDay: 0,
                    value: '15',
                    label: '15',
                    children:[]
                },
                {
                    text: '16',
                    acrossDay: 0,
                    value: '16',
                    label: '16',
                    children:[]
                },
                {
                    text: '17',
                    acrossDay: 0,
                    value: '17',
                    label: '17',
                    children:[]
                },
                {
                    text: '18',
                    acrossDay: 0,
                    value: '18',
                    label: '18',
                    children:[]
                },
                {
                    text: '19',
                    acrossDay: 0,
                    value: '19',
                    label: '19',
                    children:[]
                },
                {
                    text: '20',
                    acrossDay: 0,
                    value: '20',
                    label: '20',
                    children:[]
                },
                {
                    text: '21',
                    acrossDay: 0,
                    value: '21',
                    label: '21',
                    children:[]
                },
                {
                    text: '22',
                    acrossDay: 0,
                    value: '22',
                    label: '22',
                    children:[]
                },
                {
                    text: '23',
                    acrossDay: 0,
                    value: '23',
                    label: '23',
                    children:[]
                },
            ],
        };
    },
    methods: {
        clickEliminateRight(){
            this.clickHourChoiceRight = null;
            this.timeTextHourRight = '';
            this.timeTextHourGetRight = null;
            this.clickMinuteChoiceRight = null;
            this.timeTextMinuteRight = '';
            this.timeTextMinuteGetRight = null;
        },
        clickEliminateLeft(){
            this.clickHourChoiceLeft = null;
            this.timeTextHourLeft = '';
            this.timeTextHourGetLeft = null;
            this.clickMinuteChoiceLeft = null;
            this.timeTextMinuteLeft = '';
            this.timeTextMinuteGetLeft = null;
        },
        clickTimeAllDataLeft(item,index){
            this.clickHourChoiceLeft = index;
            this.timeTextHourLeft = `${item.label}:`;
            this.timeTextHourGetLeft = item;

            if ((this.timeTextHourGetRight != null && this.timeTextMinuteGetRight != null) && (this.timeTextHourGetLeft != null && this.timeTextMinuteGetLeft != null)) {
                if (this.timeTextHourGetRight.value <= this.timeTextHourGetLeft.value) {
                    this.clickEliminateRight();
                }
            }
        },
        clickChildrenTimeLeft(item,index){
            this.clickMinuteChoiceLeft = index;
            this.timeTextMinuteLeft = item.label;
            this.timeTextMinuteGetLeft = item;

            if ((this.timeTextHourGetRight != null && this.timeTextMinuteGetRight != null) && (this.timeTextHourGetLeft != null && this.timeTextMinuteGetLeft != null)) {
                if (this.timeTextHourGetRight.value <= this.timeTextHourGetLeft.value) {
                    this.clickEliminateRight();
                }
            }
            this.triggerLeft = false;
        },
        clickTimeAllDataRight(item,index){
            if ((this.timeTextHourGetLeft != null || this.timeTextMinuteGetLeft != null) && (this.timeTextHourGetLeft.value >= item.value)) {

            } else {
                this.clickHourChoiceRight = index;
                this.timeTextHourRight = `${item.label}:`;
                this.timeTextHourGetRight = item;
            }
        },
        clickChildrenTimeRight(item,index){
            this.clickMinuteChoiceRight = index;
            this.timeTextMinuteRight = item.label;
            this.timeTextMinuteGetRight = item;
            this.triggerRight = false;
        },
        get_time_date(){
            let date = null;
            if ((this.timeTextHourLeft && this.timeTextMinuteLeft) && (this.timeTextHourRight && this.timeTextMinuteRight) && this.timeTextHourGetRight) {
                date = {
                    //开始日期
                    startTime: `${this.timeTextHourLeft}${this.timeTextMinuteLeft}:00`,
                    //结束日期
                    endTime: `${this.timeTextHourGetRight.text}:${this.timeTextMinuteRight}:00`,
                    //是否跨日 0：否 1：是
                    acrossDay: this.timeTextHourGetRight.acrossDay,
                };
            }
            return date
        },
        set_time_date(data){
            if (!data) {}
            if (!data.acrossDay) {}
            if (!data.endTime) {}
            if (!data.startTime) {}
            const acrossDay = data.acrossDay;
            const startTime = data.startTime;
            const endTime = data.endTime;

            let startTimeHour = startTime.split(':')[0];
            let startTimeMinute = startTime.split(':')[1];
            let endTimeHour = endTime.split(':')[0];
            let endTimeMinute = endTime.split(':')[1];

            if (acrossDay == 0) {
                this.timeAllDataLeft.forEach((item,index) => {
                    if (item.label == startTimeHour) {
                        this.clickTimeAllDataLeft(item,index);
                    }
                });
                this.childrenTime.forEach((item,index) => {
                    if (item.label == startTimeMinute) {
                        this.clickChildrenTimeLeft(item,index);
                    }
                    if (item.label == endTimeMinute) {
                        this.clickChildrenTimeRight(item,index);
                    }
                });
                this.timeAllData.forEach((item,index) => {
                    if (item.label == endTimeHour) {
                        this.clickTimeAllDataRight(item,index)
                    }
                });
            } else if (acrossDay == 1) {
                this.timeAllDataLeft.forEach((item,index) => {
                    if (item.label == startTimeHour) {
                        this.clickTimeAllDataLeft(item,index);
                    }
                });
                this.childrenTime.forEach((item,index) => {
                    if (item.label == startTimeMinute) {
                        this.clickChildrenTimeLeft(item,index);
                    }
                    if (item.label == endTimeMinute) {
                        this.clickChildrenTimeRight(item,index);
                    }
                });
                this.timeAllData.forEach((item,index) => {
                    if (item.text == endTimeHour && item.acrossDay == 1) {
                        this.clickTimeAllDataRight(item,index)
                    }
                });
            }
        },
        switch_disable_true (){
            this.disabledSwitch = true;
        },
        switch_disable_false (){
            this.disabledSwitch = false;
        },
        init () {
            this.set_time_date({
                acrossDay: 1,
                startTime: "21:00:00",
                endTime: "06:00:00",
            })
        },
        reset_time_date(){
            this.clickEliminateRight();
            this.clickEliminateLeft();
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    activated() {
        this.triggerLeft = false;
        this.triggerRight = false;
    },
    deactivated() {

    },
}
