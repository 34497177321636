// consumer_car_order_getCarType 获取用车车型枚举
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://yapi.yinzhitravel.com/project/52/interface/api/15372
const consumer_car_order_getCarType = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/car/order/getCarType',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_car_order_getCarType;
